import { Link, useLocation, useNavigate } from "react-router-dom";
import ownerWebsite from "../../api/methods/ownerWebsite";
import { useEffect, useState } from "react";
import React from "react";
import ReactApexChart from "react-apexcharts";
import StatsChart from "./components/StatsChart";
import getAllStats from "../../api/methods/getAllStats";
import Seo from "../../utils/Seo";
import getCampaign from "../../api/methods/getCampaign";
import getCampaignStats from "../../api/methods/getCampaignStats";
import createCampaign from "../../api/methods/createCampaign";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import statusCampaign from "../../api/methods/statusCampaign";
import cancelWebsite from "../../api/methods/cancelCampaign";
import Swal from "sweetalert2";

const Campaign = () => {
    const [website, setWebsite] = useState([]);
    const [websiteStats, setWebsiteStats] = useState([]);
    const [coins, setCoins] = useState(undefined);
    const [selectedWebsite, setSelectedWebsite] = useState(undefined);
    const [activeType, setActiveType] = useState('7');
    const [amount, setAmount] = useState(10);
    const [type, setType] = useState(undefined);
    const [websiteSelected, setWebsiteSelected] = useState(undefined);
    const [status_Campaign, setStatusCampaign] = useState(" ");
    const location = useLocation();

    if (location && location.search && location.search?.slice(1, 8)) {
        window.location.replace("/dashboard/campaign")
    }

    const startCampaign = async () => {
        const request = await createCampaign(websiteSelected, amount, type);
        if (request && request.success === true) {
            setType(undefined)
            setAmount(10)
            if (request.type === "coin") {
                fetchWebsites();
                fetchCampaign(websiteSelected);
                fetchCampaignStats(websiteSelected, "7")
                setWebsiteSelected(undefined)
            }

            if (request.type === "money") {
                window.location.replace(request.data.url)
            }
        } else if (request && request.message) {
            toast.error(request?.message, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        }
    }

    const fetchWebsites = async () => {
        const request = await ownerWebsite();
        if (request && request.success === true) {
            setWebsite(request.data);
            setCoins(request.coins)
        } else if (request && request.success === false) {
            window.localStorage.removeItem("token")
            window.location.replace("/");
        }
    }

    const fetchCampaign = async (site) => {
        const request = await getCampaign(site);
        if (request && request.success === true) {
            setSelectedWebsite(request.data);
            setStatusCampaign(request.data.ad === "yes" ? "Pause" : "Pending");
        }
    }

    const fetchCampaignStats = async (site, type) => {
        const request = await getCampaignStats(site, type);
        if (request && request.success === true) {
            setWebsiteStats(request.data)
        }
    }

    const handleButtonClick = (site, type) => {
        setActiveType(type);
        fetchCampaignStats(site, type);
    };

    const updateStatusCampaign = async (site) => {
        const request = await statusCampaign(site);
        if (request && request.success === true) {
            setStatusCampaign(request.data === "yes" ? "Pause" : "Pending");
        }
    }

    const cancelWebsiteCampaign = async (site) => {
        Swal.fire({
            title: 'Are you sure you want to cancel the website campaign?',
            text: "You will receive a refund in coins.",
            showCancelButton: true,
            confirmButtonText: 'Delete',
            cancelButtonText: `Cancel`,
            icon: "warning",
        }).then(async (result) => {
            if (result.isConfirmed) {
                const request = await cancelWebsite(site);
                if (request && request.success === true) {
                    setSelectedWebsite(undefined);
                    fetchWebsites();
                }
            }
        })
    }

    useEffect(() => {
        fetchWebsites();
    }, []);

    useEffect(() => {
        if (website.length > 0 && !websiteSelected) {
            setWebsiteSelected(website?.filter(x => x.status === "success" && x.ad !== "yes" && x.ad !== "pending")[0]?.url?.split('//')[1].split('/')[0]);
        }
    }, [website, websiteSelected]);

    return (
        <>
            <Seo
                dynamic={true}
                title={"Manage Advertising Campaigns"}
                description={"Create and monitor custom advertising campaigns for your websites with Adsreference Campaign Manager. Drive traffic and enhance visibility by reaching your target audience effectively."}
            />
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
            <section className="home_section">
                <div className="container_main">
                    <div className="modifications-left">
                        <h2>Website Global Rewards</h2>
                        <h3 className="coins">Coins: {coins ? coins.toFixed(2) : coins} <i className="fa fa-coins"></i></h3>
                    </div>
                    <div className="modifications-block">
                        <div className="modifications-right">
                            <h2>Manage Campaign</h2>
                            <div className="website_list">
                                {
                                    website &&
                                        website.length > 0 &&
                                        website.filter(x => x.status === "success") &&
                                        website.filter(x => x.status === "success").length > 0 ?
                                        website.filter(x => x.status === "success").map((websiteInfo) => {
                                            let iconClass;

                                            if (websiteInfo.status === "warning") {
                                                iconClass = "fa fa-triangle-exclamation";
                                            } else if (websiteInfo.status === "pending") {
                                                iconClass = "fa fa-clock";
                                            } else if (websiteInfo.status === "forbidden") {
                                                iconClass = "fa fa-ban";
                                            } else if (websiteInfo.status === "success") {
                                                iconClass = "fa fa-square-check";
                                            } else if (websiteInfo.status === "widget") {
                                                iconClass = "fa fa-triangle-exclamation";
                                            }
                                            return (
                                                <>
                                                    <li className="website_redirection"
                                                        onClick={() => {
                                                            if (websiteInfo.ad === "yes" || websiteInfo.ad === "pending") {
                                                                fetchCampaign(websiteInfo.url?.split('//')[1].split('/')[0]);
                                                                fetchCampaignStats(websiteInfo.url?.split('//')[1].split('/')[0], "7")
                                                            } else {
                                                                setSelectedWebsite(undefined);
                                                            }
                                                        }}
                                                    >
                                                        <i className={`icon ${iconClass}`}></i>
                                                        {websiteInfo.url?.split('//')[1].split('/')[0]}:
                                                        <p>{websiteInfo.ad === "yes" || websiteInfo.ad === "pending" ? "Click to manage campaign." : "No campaign actually."}</p>
                                                    </li>
                                                </>
                                            );

                                        })
                                        : <p>No activate website found.</p>
                                }
                            </div>
                            <div className="button_list">
                                <button onClick={() => { setSelectedWebsite(undefined) }}>Start a campaign</button>
                                <Link to={"/dashboard/add"}><button>Add a website</button></Link>
                            </div>
                        </div>
                        <div className="modifications-left">
                            <h2>Tutorial Campaign</h2>
                            <p>1. Select the website.</p>
                            <p>2. Put the amount of clicks you want.</p>
                            <p>3. Start a campaign using your coins or money and follow the statistics.</p>
                        </div>
                    </div>
                    {
                        selectedWebsite ?
                            <>
                                <div className="modifications-left">
                                    <h2>Campaign Details for {selectedWebsite.url?.split('//')[1].split('/')[0]}</h2>
                                    <p className="widget_strong">
                                        <strong>Your advertisement is in progress please be patient ! Progress : {Number(selectedWebsite.total) - Number(selectedWebsite.queue)}/{selectedWebsite.total}.</strong>
                                        <br />
                                        <strong>{Number(selectedWebsite.queue)} users incoming.</strong>
                                    </p>
                                    <span className="info">
                                        <i class="icon fa fa-circle-info"></i>
                                        You have the option to pause or resume the campaign at any time. If you choose to cancel the campaign, you will receive a refund in coins.
                                    </span>
                                    <div className="button_list">
                                        <button onClick={() => { updateStatusCampaign(selectedWebsite.id) }}>{status_Campaign} Campaign</button>
                                        <button className="red" onClick={() => { cancelWebsiteCampaign(selectedWebsite.id) }}>Cancel Campaign</button>
                                    </div>
                                </div>
                                <div className="stats-block">
                                    <h2>Campaign statistics</h2>
                                    <div className="stats">
                                        <div>
                                            <p>Number of views: {websiteStats ? websiteStats.totalViews : undefined}</p>
                                            <p>Number of clicks: {websiteStats ? websiteStats.totalClicks : undefined}</p>
                                        </div>
                                        <div className="filter">
                                            <button
                                                onClick={() => handleButtonClick(selectedWebsite.id, '7')}
                                                style={{ backgroundColor: activeType === '7' ? '#007bff' : 'gray' }}>
                                                7d
                                            </button>
                                            <button
                                                onClick={() => handleButtonClick(selectedWebsite.id, '14')}
                                                style={{ backgroundColor: activeType === '14' ? '#007bff' : 'gray' }}>
                                                14d
                                            </button>
                                            <button
                                                onClick={() => handleButtonClick(selectedWebsite.id, '30')}
                                                style={{ backgroundColor: activeType === '30' ? '#007bff' : 'gray' }}>
                                                30d
                                            </button>
                                        </div>
                                    </div>
                                    <StatsChart websiteStats={websiteStats} />
                                </div>
                            </>
                            : <>
                                <div className="modifications-left">
                                    <h2>Create Campaign</h2>
                                    <fieldset>
                                        <legend><span class="number">1</span> Select a website</legend>
                                        <span className="info">
                                            <i class="icon fa fa-circle-info"></i>
                                            Please select the website to initiate the campaign with.
                                        </span>
                                        <select onChange={e => {
                                            const value = e.target.value;
                                            setWebsiteSelected(value);
                                        }}>
                                            {
                                                website &&
                                                website.length > 0 &&
                                                website.filter(x => x.status === "success" && x.ad !== "yes" && x.ad !== "pending").map((websiteInfo) => {
                                                    return (
                                                        <>
                                                            <option>{websiteInfo.url?.split('//')[1].split('/')[0]}</option>
                                                        </>
                                                    );
                                                })
                                            }
                                        </select>
                                    </fieldset>
                                    <fieldset>
                                        <legend><span class="number">2</span> Amount of desired clicks</legend>
                                        <span className="info">
                                            <i class="icon fa fa-circle-info"></i>
                                            Please enter the desired number of clicks for your website.
                                        </span>
                                        <input
                                            placeholder="10"
                                            type="number"
                                            step={10}
                                            min={10}
                                            max={8000}
                                            onInput={e => {
                                                const value = e.target.value;
                                                setAmount(value);
                                            }}
                                        />
                                    </fieldset>
                                    <fieldset>
                                        <legend><span class="number">3</span> Payement method</legend>
                                        <span className="info">
                                            <i class="icon fa fa-circle-info"></i>
                                            Select the desired payment method: utilize your coins or use money.
                                        </span>
                                        <div className="card_list">
                                            <div className={"card_info" + (type && type == "money" ? " hover" : "")} onClick={() => {
                                                setType("money");
                                            }}>
                                                <h3>{amount && amount > 0 ? Number(amount) / 10 + "$" : "0$"}</h3>
                                            </div>
                                            <div className={"card_info" + (type && type == "coin" ? " hover" : "")} onClick={() => {
                                                setType("coin");
                                            }}>
                                                <h3>{amount && amount > 0 ? amount + " coins" : "0 coin"}</h3>
                                            </div>
                                        </div>
                                    </fieldset>
                                    <div className="button_list">
                                        <button onClick={() => { startCampaign() }}>Start campaign</button>
                                    </div>
                                </div>
                            </>
                    }
                </div>
            </section>
        </>
    );
};
export default Campaign;