import { useEffect, useState } from "react";
import Footer from "./layout/footer";
import register from "../../api/methods/register";
import login from "../../api/methods/login";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Seo from "../../utils/Seo";

const MySwal = withReactContent(Swal)

const Login = () => {
    const signUpButton = () => {
        document.getElementById('container')?.classList.add("right-panel-active");
    }

    const signInButton = () => {
        document.getElementById('container')?.classList.remove("right-panel-active");
    }

    useEffect(() => {
        const script = document.createElement("script")
        script.src = "https://www.google.com/recaptcha/api.js?render=6LepKBYpAAAAAOwtLmfQH9THIUmk9GweEuoQkNHu"
        document.body.appendChild(script)
    }, []);

    const [email, setEmail] = useState(undefined);
    const [password, setPassword] = useState(undefined);
    const [confirm_password, setConfirm_password] = useState(undefined);
    const [username, setUsername] = useState(undefined);

    const SignUp = async () => {
        const res = await register(email, username, password, confirm_password, "EMAIL");
        if (res && res.success === true) {
            window.localStorage.setItem("token", res.token);
            if (window.localStorage && window.localStorage?.getItem("callback") && window.localStorage?.getItem("callback") === "/dashboard") {
                window.location.replace("/dashboard");
            } else {
                if (window.localStorage && window.localStorage?.getItem("callback")) {
                    window.location.replace("/callback");
                } else {
                    window.location.replace("/dashboard");
                }
            }
        } else {
            toast.error(res?.message, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        }
    }

    const SignIn = async () => {
        const res = await login(email, password, "EMAIL");
        if (res && res.success === true) {
            window.localStorage.setItem("token", res.token);
            if (window.localStorage && window.localStorage?.getItem("callback") && window.localStorage?.getItem("callback") === "/dashboard") {
                window.location.replace("/dashboard");
            } else {
                if (window.localStorage && window.localStorage?.getItem("callback")) {
                    window.location.replace("/callback");
                } else {
                    window.location.replace("/dashboard");
                }
            }
        } else {
            toast.error(res?.message, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        }
    }

    return (
        <>
            <Seo
                dynamic={true}
                title={"Adsreference - login and improve your site growth"}
                description={"Explore new opportunities to promote your website. Join our free advertising network and grow your online presence."}
            />

            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
            <section className="login_landing">
                <div className="container" id="container">
                    <div className="form-container sign-up-container">
                        <div className="form">
                            <h1>Create Account</h1>
                            <div className="social-container">
                                <a href={"/login/discord"} onClick={window.localStorage.setItem("callback", "/dashboard")} className="social">
                                    <button className="discord">Discord</button>
                                </a>
                            </div>
                            <span>or use your email for registration</span>
                            <input type="text" placeholder="Name" value={username} onInput={e => setUsername(e.target.value)} autoComplete="false" />
                            <input type="email" placeholder="Email" value={email} onInput={e => setEmail(e.target.value)} autoComplete="false" />
                            <input type="password" placeholder="Password" value={password} onInput={e => setPassword(e.target.value)} autoComplete="false" />
                            <input type="password" placeholder="Confirm Password" value={confirm_password} onInput={e => setConfirm_password(e.target.value)} autoComplete="false" />
                            <button onClick={() => { SignUp() }}>Sign Up</button>
                            <button className="responsive" onClick={() => { signInButton() }}>Sign In</button>
                        </div>
                    </div>
                    <div className="form-container sign-in-container">
                        <div className="form">
                            <h1>Sign in</h1>
                            <div className="social-container">
                                <a href={"/login/discord"} onClick={window.localStorage.setItem("callback", "/dashboard")} className="social">
                                    <button className="discord">Discord</button>
                                </a>
                            </div>
                            <span>or use your email for login</span>
                            <input type="email" placeholder="Email" value={email} onInput={e => setEmail(e.target.value)} autoComplete="false" />
                            <input type="password" placeholder="Password" value={password} onInput={e => setPassword(e.target.value)} autoComplete="false" />
                            <button onClick={() => { SignIn() }}>Sign In</button>
                            <button className="responsive" onClick={() => { signUpButton() }}>Sign Up</button>
                        </div>
                    </div>
                    <div className="overlay-container">
                        <div className="overlay">
                            <div className="overlay-panel overlay-left">
                                <h1>Welcome Back!</h1>
                                <p>To keep connected with us please login with your personal info</p>
                                <button className="ghost" id="signIn" onClick={() => { signInButton() }}>Sign In</button>
                            </div>
                            <div className="overlay-panel overlay-right">
                                <h1>Hello, welcome to Adsreference</h1>
                                <p>Enter your personal details and discover a never-before-seen marketing experience</p>
                                <button className="ghost" id="signUp" onClick={() => { signUpButton() }}>Sign Up</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};
export default Login;